import { useState } from "react";

function CreateUserForm({ onCreate }: any) {
    const [formData, setFormData] = useState<any>({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        password: '',
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onCreate(formData);
        setFormData({
            name: '',
            model: '',
            brand: '',
            description: '',
        });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <div className="column">
                    <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        placeholder="Nombres"
                    />
                    <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        placeholder="Apellidos"

                    />
                </div>
                <div className="column">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Correo"
                    />
                    <input
                        type="text"
                        name="username"
                        id="username"
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="Nombre de usuario"
                    />
                </div>
                <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Contraseña"
                />
                <button onClick={handleSubmit}>{'Crear usuario'}</button>
            </div>
        </div>
    );
}

export default CreateUserForm;