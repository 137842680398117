import { forwardRef, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

const CustomInput = forwardRef<HTMLInputElement, { value?: string; onClick?: () => void; disabled?: boolean; id?: string; title?: string; name?: string; onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void }>(({ value, onClick, disabled, id, title, name, onChange }: any, ref) => {
    return (
        <input
            ref={ref}
            type="text"
            name={name}
            id={id}
            onClick={onClick}
            value={value}
            placeholder={title}
            disabled={disabled}
            onChange={onChange}
        />
    );
});

function VehicleForm({ onCreate, data, label, disabled = false }: any) {
    const [equipos, setEquipos] = useState<any[]>([]);
    const [errors, setErros] = useState<any>({});
    const [formData, setFormData] = useState<any>(data ?? {
        brand: '',
        color: '',
        license_plate: '',
        model: '',
        year: '',
        maintenance: [],
        equipment: {},
        due_date: '',
    });
    const validationMapper: any = {
        brand: (value: any) => value === undefined || value === null || (typeof value === 'string' && value.trim() === ''),
        model: (value: any) => value === undefined || value === null || (typeof value === 'string' && value.trim() === ''),
        license_plate: (value: any) => value === undefined || value === null || (typeof value === 'string' && value.trim() === ''),
        due_date: (value: any) => {
            const exists = value && value !== null;
            if (exists) {
                const isNotEmpty = typeof value === 'string' && value.trim() !== '';
                const isNumber = typeof value === 'number';
                const isValidDate = !isNaN(Date.parse(value));
                return (isNotEmpty || isNumber) && isValidDate;
            }
            return false;
        },
        year: (value: any) => {
            if (value === undefined || value === null || (typeof value === 'string' && value.trim() === '')) {
                return true;
            }
            const number = parseFloat(value);
            if (isNaN(number) || number <= 0 || number >= 2099) {
                return true;
            }
        },
        // equipment: (value: any) => {
        //     if (Boolean(value && value !== null)) {
        //         return Object.keys(value).length === 0;
        //     }
        //     return false;
        // }
    };

    useEffect(() => {
        const fetchData = async () => {
            const equips: any[] = [];
            const querySnapshot = await getDocs(collection(db, "equipos"));
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                equips.push({ ...data, id: doc.id });
            });
            setEquipos(equips);
        };
        fetchData();
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const _errors: any = {};
        Object.keys(validationMapper).forEach(key => {
            const value = formData[key];
            if (validationMapper[key](value)) {
                _errors[key] = 'Este campo es requerido y no fue diligenciado';
            }
        });
        if (Object.keys(_errors).length === 0) {
            onCreate(formData);
            if (!data?.id) {
                setFormData({
                    brand: '',
                    color: '',
                    license_plate: '',
                    model: '',
                    year: '',
                    maintenance: [],
                    equipment: {},
                    due_date: '',
                });
            }
            setErros({});
        } else {
            setErros(_errors);
        }

    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const _errors: any = {};
        if (name === 'equipment') {
            const selectedEquipo = equipos[value];
            setFormData((prevState: any) => ({ ...prevState, [name]: selectedEquipo, equipmentIndex: value }));
        } else {
            setFormData((prevState: any) => ({ ...prevState, [name]: value }));
        }
        if (validationMapper[name] && validationMapper[name](value)) {
            _errors[name] = 'Diligencia el campo debidamente';
        }
        setErros(_errors);
    };

    const isValidForm = (): boolean => {
        return disabled || Object.keys(errors).length > 0;
    }

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <div className="column">
                    <div style={{ marginBottom: 10 }}>
                        <input
                            type="text"
                            name="brand"
                            id="brand"
                            value={formData.brand}
                            onChange={handleChange}
                            placeholder="Marca"
                            disabled={disabled}
                        />
                        {errors.brand && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.brand}</div>}
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <input
                            type="number"
                            name="year"
                            id="year"
                            value={formData.year}
                            onChange={handleChange}
                            placeholder="Año"
                            disabled={disabled}
                        />
                        {errors.year && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.year}</div>}
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <input
                            type="text"
                            name="color"
                            id="color"
                            value={formData.color}
                            onChange={handleChange}
                            placeholder="Color"
                            disabled={disabled}
                        />
                        {errors.color && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.color}</div>}
                    </div>

                </div>
                <div className="column">
                    <div style={{ marginBottom: 10 }}>
                        <input
                            type="text"
                            name="model"
                            id="model"
                            value={formData.model}
                            onChange={handleChange}
                            placeholder="Modelo"
                            disabled={disabled}
                        />
                        {errors.model && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.model}</div>}
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <input
                            type="text"
                            name="license_plate"
                            id="license_plate"
                            value={formData.license_plate}
                            onChange={handleChange}
                            placeholder="Placa"
                            disabled={disabled}
                        />
                        {errors.license_plate && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.license_plate}</div>}
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <DatePicker
                            disabled={disabled}
                            selected={formData.due_date ?? new Date()}
                            title={'Vigencia'}
                            name={'due_date'}
                            id={'due_date'}
                            onChange={date => handleChange({ target: { name: 'due_date', value: date } })}
                            customInput={<CustomInput />}
                        />
                        {errors.due_date && <div style={{ color: 'red', fontSize: 12, height: 24 }}>{errors.due_date}</div>}
                    </div>
                </div>
                <select
                    disabled={disabled}
                    value={formData.equipmentIndex}
                    name={'equipment'}
                    id={'equipment'}
                    onChange={handleChange}
                >
                    <option value="" disabled={disabled}>Equipo</option>
                    {equipos.map((equipo, index) => (
                        <option key={equipo.model} value={index}>
                            {`${equipo.brand} ${equipo.model}`}
                        </option>
                    ))}
                </select>
                <button disabled={isValidForm()} onClick={handleSubmit}>{label ?? 'Crear vehiculo'}</button>
            </div>
        </div>
    );
}

export default VehicleForm;
export { CustomInput };