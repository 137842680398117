import { useState } from "react";

function CreateEquipmentForm({ onCreate }: any) {
    const [formData, setFormData] = useState<any>({
        name: '',
        model: '',
        brand: '',
        description: '',
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onCreate(formData);
        setFormData({
            name: '',
            model: '',
            brand: '',
            description: '',
        });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <div className="column">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Nombre"
                    />
                    <input
                        type="text"
                        name="model"
                        id="model"
                        value={formData.model}
                        onChange={handleChange}
                        placeholder="Modelo"

                    />
                </div>
                <div className="column">
                    <input
                        type="text"
                        name="brand"
                        id="brand"
                        value={formData.brand}
                        onChange={handleChange}
                        placeholder="Marca"
                    />
                    <input
                        type="text"
                        name="description"
                        id="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Descripcion"
                    />
                </div>
                <button onClick={handleSubmit}>{'Crear Equipo'}</button>
            </div>
        </div>
    );
}

export default CreateEquipmentForm;