interface ModalProps {
    children: React.ReactNode;
    isShown: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, isShown = false }) => {
    return (
        isShown ? <div className={'modal-container'}>
            <div className={'modal-content'}>
                {children}
            </div>
        </div> : <></>
    );
}

export default Modal;
