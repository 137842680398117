import React, { useEffect, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { signOut } from 'firebase/auth';
import Layout from './views/layout';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user: any) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                signOut(auth);
                navigate('/login', { replace: true });
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    if (!isAuthenticated) {
        return <Layout />;
    }
    return <>{children}</>;
};


export default AuthGuard;
