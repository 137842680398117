import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebaseConfig';
import * as images from './../../assets/images';
import './index.css';
import { signInWithEmailAndPassword, signOut, browserSessionPersistence, setPersistence } from 'firebase/auth';
import { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { doc, getDoc } from 'firebase/firestore';

const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

const Login = () => {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [showError, setShowError] = useState(false);

    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>({
        email: '',
        password: '',
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                navigate('/clientes', { replace: true });
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    async function login() {
        try {
            let email = formData.email;
            if (!isValidEmail(email)) {
                const userResponse = await axiosInstance.post('/findAdminEmail', {
                    username: formData.email
                });
                email = userResponse.data.email;
            }
            const response: any = await setPersistence(auth, browserSessionPersistence)
                .then(() => {
                    return signInWithEmailAndPassword(auth, email, formData.password);
                })
                .catch((error) => {
                    throw new Error(error.message);
                });
            const docRef = doc(db, "usuarios", response.user.uid);
            const docSnap = await getDoc(docRef);
            const data: any = docSnap.data();
            if (data && data !== null && data.is_enabled) {
                navigate(`/clientes`, { replace: true });
            } else {
                await signOut(auth);
                throw new Error('User not found');
            }
        } catch (error) {
            console.log(error);
            setShowError(true);
        }
    }

    function isValidEmail(email: string) {
        return emailRegex.test(email);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="login-container">
            <div className="left-col">
                <img src={images.logo} alt="Logo" className={'login-logo'} />
                <div>
                    <input type="text" placeholder="vehigas@vehigas.com" name="email" id="email" onChange={handleChange} />

                    <div className="password-container">
                        <input type={passwordVisible ? "text" : "password"} className="password-input" placeholder="••••••••••••" name="password" id="password" onChange={handleChange} />
                        <button onClick={togglePasswordVisibility} className="toggle-password">
                            <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    {showError && <div className="error-message">Usuario o contraseña inválidos</div>}
                    <button type="submit" onClick={login}>Ingresar</button>
                    {/* <a href="/forgot-password" >Olvidé mi contraseña</a> */}
                </div>
            </div>
            <div className="right-col">
            </div>
        </div>

    );
}

export default Login;
