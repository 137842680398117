import * as images from './../../assets/images';
import './index.css';

function DocumentCard({ document, onDelete }: any) {
    return (
        <div className="document-card">
            {document.contentType.includes("image") ?
                <img src={document.url} alt="Imagen" style={{ height: 116, width: 116 }} /> :
                <img src={images.doc} alt={'Documento'} />
            }
            <h4 style={{ maxWidth: 116, textAlign: 'center', overflow: 'hidden' }}>{document.name.split('.')[0]}</h4>
            <div className="document-card-footer">

                <button className="icon-button icon-primary tooltip-text" data-tooltip="Descargar" onClick={() => {
                    window.open(document.url, '_blank');
                }}>
                    <i className="fa fa-download"></i>
                </button>
                <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => onDelete(document)}>
                    <i className="fa fa-trash"></i>
                </button>
            </div>
        </div>
    );
}

export default DocumentCard;