import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import * as Sugar from 'sugar';
import './index.css';

function AddServiceToVehicle({ onCreate }: any) {
    const [servicios, setServicios] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({
        service: {},
    });

    useEffect(() => {
        const fetchData = async () => {
            const services: any[] = [];
            const querySnapshot = await getDocs(collection(db, "servicios"));
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                services.push({ ...data, id: doc.id });
            });
            setServicios(services);
        };
        fetchData();
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onCreate(formData);
        setFormData({
            service: {},
        });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'service') {
            const selected = servicios[value];
            setFormData((prevState: any) => ({ ...prevState, [name]: selected, serviceIndex: value }));
        } else {
            setFormData((prevState: any) => ({ ...prevState, [name]: value }));
        }
    };

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <select
                    value={formData.serviceIndex}
                    name={'service'}
                    id={'service'}
                    onChange={handleChange}
                >
                    <option value="" >Elige un servicio</option>
                    {servicios.map((equipo, index) => (
                        <option key={equipo.name} value={index}>
                            {`${equipo.name} ($${equipo.amount}`})
                        </option>
                    ))}
                </select>
                <button onClick={handleSubmit} disabled={formData.service === null || Sugar.Object.isEmpty(formData.service)}>{'Agregar servicio'}</button>
            </div>
        </div>
    );
}

export default AddServiceToVehicle;