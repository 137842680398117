import * as Sugar from 'sugar';
import { useNavigate, useParams } from "react-router-dom";
import { query, collection, onSnapshot, doc, getDoc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import { useState, useEffect, useCallback } from "react";
import Modal from '../../components/modal';
import CreateClientForm from '../../components/new-client-form';
import axiosInstance from './../../axiosConfig';
import { Table } from '../../components/table';
import { db } from "../../firebaseConfig";
import './index.css';
import VehicleForm from '../../components/new-vehicle-form';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";

function ClientPage() {
    const navigate = useNavigate();
    const { userId } = useParams<"userId">();
    const [filter, setFilter] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [vehiculos, setVehiculos] = useState<any[]>([]);
    const [cliente, setClient] = useState<any>();
    const [deleteModal, setDeleteModal] = useState(false);
    const [vehicleToDelete, setVehicleToDelete] = useState<any>(null);
    const [vehicleData, setVehicleData] = useState<any>({});
    const [formData, setFormData] = useState<any>({
        username: '',
        password: '',
    });

    const fetchData = useCallback(async () => {
        const q = query(collection(db, `clientes/${userId}/vehiculos`));
        const docRef = doc(db, "clientes", userId as string);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setClient({ id: docSnap.id, ...docSnap.data() });
            setFormData({ ...formData, username: docSnap.data().username ?? '' })
            return onSnapshot(q, (querySnapshot) => {
                const vehicles: any[] = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    vehicles.push({
                        id: doc.id,
                        ...data,
                        status: (data?.is_enabled ?? false) ? 'Activo' : 'Inactivo',
                        created_at_year: new Date(data.created_at).getFullYear(),
                        created_at: Sugar.Date.format(new Date(data.created_at), '{dd}-{MM}-{yyyy}'),
                        maintenance_at: data.last_maintenance_at ? Sugar.Date.format(Sugar.Date.addMonths(new Date(data.last_maintenance_at), 6), '{dd}-{MM}-{yyyy}') : ''
                    });
                });
                setVehiculos(vehicles);
                setLoading(false);
            });
        } else {
            setLoading(false);
            setClient(null);
            return null;
        }
    }, [userId]);

    useEffect(() => {
        let unsubscribe: (() => void) | null = null;
        fetchData().then((unsub) => {
            unsubscribe = unsub;
        });
        return () => {
            if (unsubscribe) { unsubscribe(); }
        };
    }, []);

    function onDataFiltered(evt: any) {
        const { value } = evt.target;
        setFilter(value.toLowerCase());
    }

    const updateUser = async (e: any) => {
        e.preventDefault();
        const hasUserName = formData.username && !Sugar.String.isBlank(formData.username);
        const hasPassword = formData.password && !Sugar.String.isBlank(formData.password);
        const documentRef = doc(db, "clientes", userId as string);
        if (cliente.username) {
            if (hasUserName) {
                const username = formData.username.toString().trim().toLowerCase();
                await updateDoc(documentRef, { username });
            }
            if (hasPassword) {
                await axiosInstance.post('/changeUserPassword', {
                    id: cliente.user_id,
                    password: btoa(formData.password.trim())
                });
            }
        } else {
            if (hasPassword && hasUserName) {
                const username = formData.username.toString().trim().toLowerCase();
                const [email, password] = [cliente.email.toLowerCase().trim(), formData.password.trim()];
                const response: any = await axiosInstance.post('/newUser', {
                    _id: userId as string,
                    email,
                    name: Sugar.String.capitalize(cliente.first_name, true, true).trim(),
                    password: btoa(password.trim())
                });
                await updateDoc(documentRef, { username, user_id: response.data.userId });
                setFormData({ ...formData, username });
                cliente.username = username;
            }
        }
        setFormData({
            username: '',
            password: '',
        });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    async function createVehicle({ equipmentIndex, ...data }: any) {
        setShowModal(false);
        const value = { ...data, is_enabled: true, created_at: new Date().getTime(), due_date: Sugar.Date.set(new Date(data.due_date), { hours: 23, minutes: 59, seconds: 59 }).getTime() };
        await addDoc(collection(db, "clientes", userId as string, 'vehiculos'), value);
        console.log(value);
    }

    async function updateClient(data: any) {
        const documentRef = doc(db, "clientes", userId as string);
        await updateDoc(documentRef, data);
        setIsDisabled(true);
    }

    async function removeVehicle() {
        setDeleteModal(false);
        await deleteDoc(doc(db, "clientes", userId as string, 'vehiculos', vehicleToDelete.id));
        setVehicleToDelete(null);
    }

    function showVehicleDataLabel(row: any) {
        setVehicleData(row);
        setShowQRModal(true);
    }

    function download() {
        const element = document.getElementById("qr-container");
        html2canvas(element!, {
            scale: 1,
            useCORS: true
        }).then(canvas => {
            var _canvas = document.createElement("canvas");
            _canvas.width = 300;
            _canvas.height = 420;
            var ctx = _canvas.getContext('2d');
            ctx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 300, 420);
            var myImage = _canvas.toDataURL("image/png");
            downloadURI(myImage, "vehigas_sello" + vehicleData.license_plate + ".png");
        });
    }

    function downloadURI(uri: string, name: string) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
    }


    const displayedData = Sugar.String.isBlank(filter) ? vehiculos : vehiculos.filter((vehiculo) => {
        return vehiculo.brand.toLowerCase().includes(filter) || vehiculo.model.includes(filter) || vehiculo.license_plate.includes(filter);
    });

    return (
        <div>
            <h1>{loading ? 'Buscando clientes...' : ''}</h1>
            {!loading && <>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">Datos del Cliente</div>
                        <button className="icon-button icon-gray tooltip-text" data-tooltip="Editar Campos" onClick={() => {
                            setIsDisabled(!isDisabled)
                        }} >
                            <i className={`fas ${isDisabled ? 'fa-pencil-alt' : 'fa-ban'}`}></i>
                        </button>
                    </div>
                    <CreateClientForm onCreate={updateClient} data={cliente} label={'Actualizar cliente'} disabled={isDisabled} />
                </div>

                <div className="card">
                    <div className="card-header">
                        <div className="card-title">Datos de usuario</div>
                    </div>
                    <div className="form-container">
                        <div className={`form-content show`}>
                            <div className="column">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    placeholder="Nombre de usuario"
                                />
                            </div>
                            <div className="column">
                                <input
                                    name="password"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Contraseña"
                                    type="password"
                                />
                            </div>
                            <button onClick={updateUser}>{cliente.user_id ? 'Actualizar Usuario' : 'Crear Usuario'}</button>
                        </div>
                    </div>
                </div>

                <h3>Listado de vehiculos registrados a nombre del cliente {Sugar.String.capitalize(cliente.first_name, true, true)}.</h3>
                <div className={'form-filter-container'}>
                    <div className="search-container">
                        <input
                            type="text"
                            onChange={onDataFiltered}
                            placeholder="Busca por placa, marca, modelo..."
                        />
                    </div>
                    <button onClick={() => { setShowModal(true); }}>{'Nuevo Vehiculo'}</button>
                </div>
                <Table headers={[
                    // 'Mantenimiento',
                    'Marca',
                    'Modelo',
                    'Año',
                    'Color',
                    'Placa',
                    'Estado',
                    'Fecha de Ingreso',
                ]}
                    keys={[
                        // 'next_maintenance',
                        'brand',
                        'model',
                        'year',
                        'color',
                        'license_plate',
                        'status',
                        'created_at',
                    ]}
                    values={displayedData}
                    actionBuilder={(row) => {
                        return (<div className={'table-actions-container'}>
                            <button onClick={() => {
                                navigate(`/cliente/${userId}/vehiculo/${row.id}`);
                            }}>{'Ver detalle'}</button>
                            <button className="icon-button icon-yellow tooltip-text" onClick={() => {
                                showVehicleDataLabel(row);
                            }} data-tooltip="Ver QR">
                                <i className={`fas fa-file`}></i>
                            </button>
                            <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                                setVehicleToDelete(row);
                                setDeleteModal(true);
                            }}>
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>);
                    }}
                />
                <Modal isShown={showModal}>
                    <VehicleForm onCreate={createVehicle} />
                    <button className={'modal-close-button'} onClick={() => { setShowModal(false); }}>Cancelar</button>
                </Modal>
                <Modal isShown={showQRModal}>
                    {vehicleData && !Sugar.Object.isEmpty(vehicleData) &&
                        <>
                            <div className={"vehigas-container"} id={'qr-container'}>
                                <div className={'vehigas-info-container'}>
                                    <div className={"vehigas-title"}>VEHIGAS</div>
                                    <div className={"vehigas-info"}>{cliente.first_name}</div>
                                    <div className={"vehigas-info"}>{cliente?.last_name ?? ''}</div>
                                    <div className={"vehigas-info"}>{vehicleData.license_plate}</div>
                                    <div className={"vehigas-year"}>{vehicleData.created_at_year}</div>
                                </div>
                                <div style={{ flex: 1 }} />
                                <div className={"qr-placeholder"}>
                                    <QRCode
                                        size={150}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={btoa(`${vehicleData.id}/${userId}`)}
                                        viewBox={`0 0 150 150`}
                                    />
                                </div>
                                <div className={"validity"}>VÁLIDO: {Sugar.Date.format(new Date(vehicleData.due_date), '{dd}-{MM}-{yyyy}')}</div>
                            </div>
                        </>
                    }
                    <button style={{ marginBottom: 0 }} onClick={download}>Descargar</button>
                    <button className={'modal-close-button'} onClick={() => { setShowQRModal(false); }}>Cerrar</button>
                </Modal>
                <Modal isShown={deleteModal}>
                    <h2>Confirmación de eliminación de vehiculo</h2>
                    <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar el vehiculo de placa {vehicleToDelete?.license_plate} ?</h4>
                    <button style={{ marginBottom: 0 }} onClick={removeVehicle}>Confirmar</button>
                    <button className={'modal-close-button'} onClick={() => { setDeleteModal(false); }}>Cancelar</button>
                </Modal>
            </>}
        </div>
    );
}

export default ClientPage;