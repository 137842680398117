import * as Sugar from 'sugar';
import { useParams } from "react-router-dom";
import { query, collection, onSnapshot, doc, getDoc, updateDoc, addDoc, deleteDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useState, useEffect, useCallback } from "react";
import Modal from '../../components/modal';
import { Table } from '../../components/table';
import { db, storage } from "../../firebaseConfig";
import './index.css';
import VehicleForm, { CustomInput } from '../../components/new-vehicle-form';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import AddServiceToVehicle from '../../components/add-vehicle-service-form';
import UploadForm from '../../components/new-document-form';
import DocumentCard from '../../components/document-card';

function VehiclePage() {
    const { userId } = useParams<"userId">();
    const { vehicleId } = useParams<"vehicleId">();
    const [filter, setFilter] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showMaintenanceServiceModal, setShowMaintenanceServiceModal] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [servicios, setServicios] = useState<any[]>([]);
    const [documents, setDocuments] = useState<any[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteMaintenanceModal, setDeleteMaintenanceModal] = useState(false);
    const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState<any>(null);
    const [maintenanceToDelete, setMaintenanceToDelete] = useState<any>(null);
    const [documentToDelete, setDocumentToDelete] = useState<any>(null);
    const [maintenanceToAddService, setMaintenanceToAddService] = useState<any>(null);
    const [formData, setFormData] = useState<any>({
        next_maintenance: '',
        file_name: '',
        file: '',
    });
    const [vehiculo, setVehicle] = useState<any>();
    const [cliente, setClient] = useState<any>();

    const fetchData = useCallback(async () => {
        const docRef = doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string);
        const clientRef = doc(db, "clientes", userId as string);
        const clientSnap = await getDoc(clientRef);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setClient({ id: clientSnap.id, ...clientSnap.data() });
            setVehicle({ id: docSnap.id, ...docSnap.data() });
            setFormData((f: any) => ({ ...f }));
            setLoading(false);
        } else {
            setLoading(false);
            setVehicle(null);
            return null;
        }
    }, [userId, vehicleId]);


    const fetchDocumentsData = useCallback(async () => {
        const q = query(collection(db, `clientes/${userId}/vehiculos/${vehicleId}/documents`));
        return onSnapshot(q, (querySnapshot) => {
            const docs: any[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                docs.push({
                    id: doc.id,
                    ...data,
                    status: (data?.is_enabled ?? false) ? 'Activo' : 'Inactivo',
                    created_at: Sugar.Date.format(new Date(data.created_at), '{dd}-{MM}-{yyyy}'),
                });
            });
            setDocuments(docs);
        });
    }, [userId, vehicleId]);

    const fetchServicesData = useCallback(async () => {
        const q = query(collection(db, `clientes/${userId}/vehiculos/${vehicleId}/services`));
        return onSnapshot(q, (querySnapshot) => {
            const servs: any[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                servs.push({
                    id: doc.id,
                    ...data,
                    created_at: Sugar.Date.format(new Date(data.created_at), '{dd}-{MM}-{yyyy}'),
                });
            });
            setServicios(servs);
        });
    }, [userId, vehicleId]);

    useEffect(() => {
        let documents_unsubscribe: (() => void) | null = null;
        let services_unsubscribe: (() => void) | null = null;
        fetchData();
        fetchDocumentsData().then((unsub) => {
            documents_unsubscribe = unsub;
        });
        fetchServicesData().then((unsub) => {
            services_unsubscribe = unsub;
        });
        return () => {
            if (documents_unsubscribe) { documents_unsubscribe(); }
            if (services_unsubscribe) { services_unsubscribe(); }
        };
    }, [fetchData, fetchDocumentsData, fetchServicesData]);

    function onDataFiltered(evt: any) {
        const { value } = evt.target;
        setFilter(value.toLowerCase());
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    async function addService({ serviceIndex, ...data }: any) {
        setShowModal(false);
        const value = { ...data.service, is_enabled: true, created_at: new Date().getTime() };
        await addDoc(collection(db, "clientes", userId as string, 'vehiculos', vehicleId as string, 'services'), value);
    }

    async function addServiceToMaincenance({ serviceIndex, ...data }: any) {
        setShowMaintenanceServiceModal(false);
        const documentRef = doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string);
        const value = { ...data.service, is_enabled: true, created_at: new Date().getTime() };
        const result = (vehiculo?.maintenance ?? []);
        result.forEach((element: any, i: number) => {
            if (i === maintenanceToAddService.index) {
                element.service = value;
            }
        });;
        await updateDoc(documentRef, { maintenance: result });
        setVehicle({ ...vehiculo, maintenance: result });
        setMaintenanceToAddService(null);
    }

    async function updateVehicle(data: any) {
        const documentRef = doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string);
        const value = { ...data, due_date: Sugar.Date.set(new Date(data.due_date), { hours: 23, minutes: 59, seconds: 59 }).getTime() };
        await updateDoc(documentRef, value);
        setIsDisabled(true);
    }

    async function removeVehicle() {
        setDeleteModal(false);
        await deleteDoc(doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string, 'services', serviceToDelete.id));
        setServiceToDelete(null);
    }

    async function removeMaintenance() {
        setDeleteMaintenanceModal(false);
        const documentRef = doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string);
        const result = (vehiculo?.maintenance ?? []).filter(({ date }: any) => Sugar.Date.format(new Date(date), '{dd}-{MM}-{yyyy}') !== maintenanceToDelete.date);
        await updateDoc(documentRef, { maintenance: result });
        setVehicle({ ...vehiculo, maintenance: result });
        setMaintenanceToDelete(null);
    }

    async function removeDocument() {
        setDeleteDocumentModal(false);
        await deleteDoc(doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string, 'documents', documentToDelete.id));
        setDocumentToDelete(null);
    }

    function download() {
        const element = document.getElementById("qr-container");
        html2canvas(element!, {
            scale: 1,
            useCORS: true
        }).then(canvas => {
            var _canvas = document.createElement("canvas");
            _canvas.width = 300;
            _canvas.height = 420;
            var ctx = _canvas.getContext('2d');
            ctx?.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 300, 420);

            var myImage = _canvas.toDataURL("image/png");
            downloadURI(myImage, "vehigas_sello" + vehiculo.license_plate + ".png");
        });
    }

    function downloadURI(uri: string, name: string) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
    }

    function onFileChanged({ text, file }: any) {
        const extension: string = Sugar.Array.last(file.name.split('.'));
        const regex = /(jpg|jpeg|png|gif|bmp|tiff)$/i;
        const metadata = {
            contentType: !regex.test(extension) ? 'document' : 'image',
            name: `${text}.${extension}`,
        };
        const storageRef = ref(storage, `documents/${vehicleId}` + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        uploadTask.on('state_changed', () => { },
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        break;
                    case 'storage/canceled':
                        break;
                    case 'storage/unknown':
                        break;
                }
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await addDoc(collection(db, "clientes", userId as string, 'vehiculos', vehicleId as string, 'documents'), { ...metadata, url: downloadURL, created_at: new Date().getTime(), });
                    setShowUploadFileModal(false);
                });
            }
        );
    }

    async function addMaintenance() {
        const documentRef = doc(db, "clientes", userId as string, 'vehiculos', vehicleId as string);
        let maintenance = vehiculo.maintenance ?? [];
        maintenance = [...maintenance, { date: Sugar.Date.set(new Date(formData.next_maintenance), { hours: 23, minutes: 59, seconds: 59 }).getTime(), }]
        setVehicle({ ...vehiculo, maintenance });
        await updateDoc(documentRef, { maintenance });
        setFormData({ next_maintenance: '' });
    }

    const pendingMaintenances = (vehiculo?.maintenance ?? []).filter((maintenance: any) => Sugar.Date.isAfter(new Date(maintenance.date), new Date()))

    const displayedMaintenances = (vehiculo?.maintenance ?? []);
    const transformedMaintenances = displayedMaintenances.map((maintenance: any, i: number) => {
        return {
            ...maintenance,
            index: i,
            date: Sugar.Date.format(new Date(maintenance.date), '{dd}-{MM}-{yyyy}'),
            service: maintenance.service?.name ?? '',
            is_pending: !Boolean(maintenance.service && maintenance.service !== null) ? 'Si' : 'No',
        };
    });
    const _services = servicios.map((v) => ({ ...v, from_maintenance: 'No' }));
    const _maintenances = displayedMaintenances.filter((v: any) => Boolean(v?.service && v?.service !== null)).map((v: any) => ({
        ...v.service,
        from_maintenance: 'Si',
        created_at: Sugar.Date.format(new Date(v.service.created_at), '{dd}-{MM}-{yyyy}')
    }))
    let displayedData = [..._maintenances, ..._services];
    displayedData = Sugar.String.isBlank(filter) ? displayedData : displayedData.filter((servicio) => {
        return servicio.name.toLowerCase().includes(filter);
    });

    return (
        <div style={{ paddingBottom: 16, backgroundColor: '#e9eef7' }}>
            <h1>{loading ? 'Obteniendo información...' : ''}</h1>
            {!loading && <>
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">Datos del vehiculo</div>
                        <button className="icon-button icon-primary tooltip-text" data-tooltip="Ver QR" onClick={() => {
                            setShowQRModal(true)
                        }} >
                            <i className={'fas fa-file'}></i>
                        </button>
                        <button className="icon-button icon-gray tooltip-text" data-tooltip="Editar Campos" onClick={() => {
                            setIsDisabled(!isDisabled)
                        }} >
                            <i className={`fas ${isDisabled ? 'fa-pencil-alt' : 'fa-ban'}`}></i>
                        </button>
                    </div>
                    <VehicleForm onCreate={updateVehicle} data={vehiculo} label={'Actualizar Vehiculo'} disabled={isDisabled} />
                </div>

                <div className="card">
                    <div className="card-header" style={{ gap: 8 }}>
                        <div className="card-title">Contenido multimedia y archivos</div>
                        <button style={{ width: 150, margin: 0, textAlign: 'center' }} onClick={() => {
                            setShowUploadFileModal(true);
                        }}>{'Subir archivo'}</button>
                    </div>
                    {documents.length === 0 ? <p>No se encontraron archivos</p> : <div className="file-list">
                        {documents.map((document: any) => (
                            <DocumentCard key={document.url} document={document} onDelete={(row: any) => {
                                setDocumentToDelete(row);
                                setDeleteDocumentModal(true);
                            }
                            } />
                        ))}
                    </div>}
                </div>

                <div className="card">
                    <div className="card-header">
                        <div className="card-title">Agenda el próximo mantenimiento {pendingMaintenances && pendingMaintenances.length > 0 ? Sugar.Date.format(new Date(pendingMaintenances[0].date), '(Siguiente: {dd}-{MM}-{yyyy})') : ''}</div>
                    </div>
                    <div className="form-container">
                        <div className={`form-content row`}>
                            <DatePicker
                                selected={formData.next_maintenance}
                                title={'Fecha del próximo mantenimiento'}
                                name={'next_maintenance'}
                                id={'next_maintenance'}
                                onChange={date => handleChange({ target: { name: 'next_maintenance', value: date } })}
                                customInput={<CustomInput />}
                            />
                            <button disabled={!formData.next_maintenance} onClick={addMaintenance}>{'Agendar mantenimiento'}</button>
                        </div>
                    </div>
                </div>

                {transformedMaintenances && transformedMaintenances.length > 0 && <>
                    <h3>Listado de mantenimientos registrados al vehiculo.</h3>
                    <Table headers={[
                        'Fecha del mantenimiento',
                        'Servicio',
                        'Esta pendiente?',
                    ]}
                        keys={[
                            'date',
                            'service',
                            'is_pending',
                        ]}
                        values={transformedMaintenances}
                        actionBuilder={(row) => {
                            return (<div className={'table-actions-container'}>
                                <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                                    setMaintenanceToDelete(row);
                                    setDeleteMaintenanceModal(true);
                                }}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                                <button className="icon-button icon-primary tooltip-text" data-tooltip="Añadir servicio" onClick={() => {
                                    setMaintenanceToAddService(row);
                                    setShowMaintenanceServiceModal(true);
                                }}>
                                    <i className="fas fa-wrench"></i>
                                </button>
                            </div>);
                        }}
                    />
                </>}

                <>
                    <h3>Listado de servicios registrados al vehiculo.</h3>
                    <div className={'form-filter-container'}>
                        <div className="search-container">
                            <input
                                type="text"
                                onChange={onDataFiltered}
                                placeholder="Busca por nombre de servicio..."
                            />
                        </div>
                        <button onClick={() => { setShowModal(true); }}>{'Nuevo servicio'}</button>
                    </div>
                    {displayedData && displayedData.length > 0 &&
                        <Table headers={[
                            'Mantenimiento obligatorio?',
                            'Tipo de servicio',
                            'Costo del servicio',
                            'Fecha de servicio',
                        ]}
                            keys={[
                                'from_maintenance',
                                'name',
                                'amount',
                                'created_at',
                            ]}
                            values={displayedData}
                            actionBuilder={(row) => {
                                return (<div className={'table-actions-container'}>
                                    <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                                        setServiceToDelete(row);
                                        setDeleteModal(true);
                                    }}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>);
                            }}
                        />}
                </>
                <Modal isShown={showModal}>
                    <AddServiceToVehicle onCreate={addService} />
                    <button className={'modal-close-button'} onClick={() => { setShowModal(false); }}>Cancelar</button>
                </Modal>
                <Modal isShown={showMaintenanceServiceModal}>
                    <AddServiceToVehicle onCreate={addServiceToMaincenance} />
                    <button className={'modal-close-button'} onClick={() => { setShowMaintenanceServiceModal(false); }}>Cancelar</button>
                </Modal>
                <Modal isShown={showUploadFileModal}>
                    <UploadForm onUpload={onFileChanged} onClose={() => { setShowUploadFileModal(false); }} />
                </Modal>
                <Modal isShown={showQRModal}>
                    {vehiculo && !Sugar.Object.isEmpty(vehiculo) &&
                        <>
                            <div className={"vehigas-container"} id={'qr-container'}>
                                <div className={'vehigas-info-container'}>
                                    <div className={"vehigas-title"}>VEHIGAS</div>
                                    <div className={"vehigas-info"}>{cliente.first_name}</div>
                                    <div className={"vehigas-info"}>{cliente?.last_name ?? ''}</div>
                                    <div className={"vehigas-info"}>{vehiculo.license_plate}</div>
                                    <div className={"vehigas-year"}>{new Date(vehiculo.created_at).getFullYear()}</div>
                                </div>
                                <div style={{ flex: 1 }} />
                                <div className={"qr-placeholder"}>
                                    <QRCode
                                        size={150}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={btoa(`${vehiculo.id}/${userId}`)}
                                        viewBox={`0 0 150 150`}
                                    />
                                </div>
                                <div className={"validity"}>VÁLIDO: {Sugar.Date.format(new Date(vehiculo.due_date), '{dd}-{MM}-{yyyy}')}</div>
                            </div>
                        </>
                    }
                    <button style={{ marginBottom: 0 }} onClick={download}>Descargar</button>
                    <button className={'modal-close-button'} onClick={() => { setShowQRModal(false); }}>Cerrar</button>
                </Modal>
                <Modal isShown={deleteModal}>
                    <h2>Confirmación de eliminación de servicio para vehiculo</h2>
                    <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar el servicio de {serviceToDelete?.name} ?</h4>
                    <button style={{ marginBottom: 0 }} onClick={removeVehicle}>Confirmar</button>
                    <button className={'modal-close-button'} onClick={() => { setDeleteModal(false); }}>Cancelar</button>
                </Modal>
                <Modal isShown={deleteMaintenanceModal}>
                    <h2>Confirmación de eliminación del mantenimiento para el vehiculo</h2>
                    <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar el mantenimiento de {maintenanceToDelete?.date} ?</h4>
                    <button style={{ marginBottom: 0 }} onClick={removeMaintenance}>Confirmar</button>
                    <button className={'modal-close-button'} onClick={() => { setDeleteMaintenanceModal(false); }}>Cancelar</button>
                </Modal>
                <Modal isShown={deleteDocumentModal}>
                    <h2>Confirmación de eliminación del documento para el vehiculo</h2>
                    <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar el documento de {documentToDelete?.name} ?</h4>
                    <button style={{ marginBottom: 0 }} onClick={removeDocument}>Confirmar</button>
                    <button className={'modal-close-button'} onClick={() => { setDeleteDocumentModal(false); }}>Cancelar</button>
                </Modal>
            </>}
        </div>
    );
}

export default VehiclePage;