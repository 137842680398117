import { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { collection, doc, query, addDoc, updateDoc, onSnapshot, deleteDoc, getDocs } from "firebase/firestore";
import Modal from "../../components/modal";
import { Table } from "../../components/table";
import CreateClientForm from "../../components/new-client-form";
import { db } from './../../firebaseConfig';
import * as Sugar from 'sugar';
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

const Cliente = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState<any[]>([]);
    const [vehicles, setClientVehicles] = useState<any[]>([]);
    const [vehiclesData, setVehiclesData] = useState<any[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [clientToDelete, setClientToDelete] = useState<any>(null);
    // const [vehiclesWithError, setVehiclesWithError] = useState<any[]>([]);

    const fetchData = useCallback(async () => {
        const q = query(collection(db, "clientes"));
        return onSnapshot(q, (querySnapshot) => {
            const clients: any[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                clients.push(
                    {
                        id: doc.id,
                        ...data,
                        name: Sugar.String.capitalize(`${data?.first_name ?? ''} ${data?.last_name ?? ''}`, true, true),
                        status: (data?.is_enabled ?? false) ? 'Activo' : 'Inactivo',
                    }
                );
            });
            setClientes(clients);
            setLoading(false);
        });
    }, []);

    const fetchVehiclesData = useCallback(async () => {
        let data: any = [];
        let vehicles_data = [];
        let vehicles_without_equipment: any[] = [];
        let promises = clientes.map(async (client) => {
            const docRef = collection(db, "clientes", client.id, 'vehiculos');
            const docSnap = await getDocs(docRef);
            let vehiculos = docSnap.docs.map(doc => doc.data().license_plate);
            vehicles_data = docSnap.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            vehicles_without_equipment = [...vehicles_without_equipment, ...vehicles_data.filter((v: any) => !Boolean(v?.equipment && v?.equipment !== null && !Sugar.Object.isEmpty(v?.equipment))).map(v => ({ clientId: client.id, vehicleId: v.id }))]
            data = [...data, { id: client.id, vehiculos: vehicles_data }];
            return { id: client.id, vehiculos };
        });
        vehicles_without_equipment = Sugar.Array.flatten(vehicles_without_equipment);
        const _vehiculos = await Promise.all(promises);
        setClientVehicles(_vehiculos);
        // setVehiclesWithError(vehicles_without_equipment);
        setVehiclesData(data);
    }, [clientes]);



    useEffect(() => {
        let unsubscribe: (() => void) | null = null;
        fetchData().then((unsub) => {
            unsubscribe = unsub;
        });
        return () => {
            if (unsubscribe) { unsubscribe(); }
        };
    }, [fetchData]);

    useEffect(() => {
        if (!loading && clientes.length > 0) {
            fetchVehiclesData();
        }
    }, [loading, clientes, fetchVehiclesData]);

    useEffect(() => {
        setError(null);
    }, [showModal]);

    function onDataFiltered(evt: any) {
        const { value } = evt.target;
        setFilter(value.toLowerCase());
    }

    async function createClient(data: any) {
        setError(null);
        const oldClient = clientes.find(client => client.dni === data.dni);
        if (oldClient && oldClient !== null) {
            setError(`Oops!! ya existe un cliente con el dni ${data.dni}`);
            return;
        }
        setShowModal(false);
        const value = { ...data, is_enabled: true, created_at: new Date().getTime() };
        await addDoc(collection(db, "clientes"), value);
    }

    async function updateStatus(row: any) {
        const documentRef = doc(db, "clientes", row.id);
        await updateDoc(documentRef, { is_enabled: !row.is_enabled });
    }

    async function removeClient() {
        setDeleteModal(false);
        await deleteDoc(doc(db, "clientes", clientToDelete.id));
        setClientToDelete(null);
    }

    function findClientByLicense(placa: string) {
        const placaLowerCase = placa.toLowerCase();
        const client = vehicles.find(cliente =>
            cliente?.vehiculos.some((vehiculo: any) =>
                vehiculo.toLowerCase().includes(placaLowerCase)
            )
        );
        if (client) {
            return [clientes.find((c) => c.id === client.id)];
        }
        return [];
    }

    // async function fixEquipmentError() {
    //     console.log(vehiclesWithError);
    //     const promises = vehiclesWithError.map((value: any) => {
    //         const documentRef = doc(db, "clientes", value.clientId as string, 'vehiculos', value.vehicleId as string);
    //         return updateDoc(documentRef, {
    //             equipment: {
    //                 "description": "PRODUCTO ITALIANO",
    //                 "model": "LAN-45P",
    //                 "brand": "LANDIRENZO",
    //                 "name": "SISTEMA GLP",
    //                 "id": "MFNzCGwRhXNraC0kxnNr"
    //             }
    //         });
    //     });
    //     await Promise.all(promises);
    // }

    function downloadMaintenances() {
        const now = new Date().getTime();
        let data: any[] = [];
        vehiclesData.forEach(({ id: clientId, vehiculos }) => {
            const client: any = clientes.find(v => v.id === clientId);
            vehiculos.forEach(({ maintenance }: any) => {
                maintenance.filter((value: any) => new Date(value.date).getTime() > now).forEach((v: any) => {
                    data = [...data, {
                        'DNI del cliente ': client?.dni ?? '',
                        'Nombre del cliente': client?.first_name ?? '',
                        Vehiculo: v.license_plate,
                        'Fecha de mantenimiento': Sugar.Date.format(new Date(v.date), '{dd}-{MM}-{yyyy}', 'es')
                    }];
                });
            });
        });
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Mantenimientos");
        var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        var buf = new ArrayBuffer(wbout.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xff;
        saveAs(
            new Blob([buf], { type: "application/octet-stream" }),
            "Mantenimientos.xlsx"
        );
    }

    const filteredData = Sugar.String.isBlank(filter) ? clientes : clientes.filter((cliente) => {
        return cliente.name.toLowerCase().includes(filter) || cliente.dni.includes(filter) || cliente.phone.includes(filter) || cliente.email.toLowerCase().includes(filter);
    });
    const displayedData = Sugar.Array.unique([...filteredData, ...findClientByLicense(filter)], (c) => c.id);
    const size = displayedData.length;
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <h1>{loading ? 'Buscando clientes...' : (size === 0 ? 'Sin resultados' : `${size} ${Sugar.String.pluralize('Cliente', size)} en total`)}</h1>
                <button onClick={downloadMaintenances} style={{ width: 200, height: 40 }}>{'Mantenimientos próximos'}</button>
            </div>
            <h3>Listado de clientes registrados en nuestra plataforma.</h3>
            <div className={'form-filter-container'}>
                <div className="search-container">
                    <input
                        type="text"
                        onChange={onDataFiltered}
                        placeholder="Busca por nombre, cédula, correo, usuario o teléfono..."
                    />
                </div>
                <button onClick={() => { setShowModal(true); }}>{'Nuevo Cliente'}</button>

            </div>
            <Table headers={[
                'Nombre',
                'Cédula',
                'Teléfono',
                'Correo',
                'Estado',
            ]}
                keys={[
                    'name',
                    'dni',
                    'phone',
                    'email',
                    'status',
                ]}
                values={displayedData}
                actionBuilder={(row) => {
                    return (<div className={'table-actions-container'}>
                        <button onClick={() => {
                            navigate(`/cliente/${row.id}`);
                        }}>{'Ver detalle'}</button>
                        <button className="icon-button icon-red tooltip-text" onClick={() => { updateStatus(row); }} data-tooltip="Inactivar">
                            <i className={`fas ${row.is_enabled ? 'fa-ban' : 'fa-check'}`}></i>
                        </button>
                        <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                            setClientToDelete(row);
                            setDeleteModal(true);
                        }}>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>);
                }}
            />
            <Modal isShown={showModal}>
                {error &&
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: 'red' }}>{error}</span>
                    </div>
                }
                <CreateClientForm onCreate={createClient} />
                <button className={'modal-close-button'} onClick={() => { setShowModal(false); }}>Cancelar</button>
            </Modal>
            <Modal isShown={deleteModal}>
                <h2>Confirmacion de eliminación de usuario</h2>
                <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar a {clientToDelete?.name}?</h4>
                <button style={{ marginBottom: 0 }} onClick={removeClient}>Confirmar</button>
                <button className={'modal-close-button'} onClick={() => { setDeleteModal(false); }}>Cancelar</button>
            </Modal>
        </div>
    );
};

export default Cliente;