import React, { useState, useRef } from 'react';
import './index.css';

function UploadForm({ onUpload, onClose }: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: any) => {
        handleSubmit(e.target.files[0]);
    };

    const handleSubmit = (file: File | null) => {
        setIsLoading(true);
        if (file !== null) {
            onUpload({ text, file });
        }
    };

    return (
        <>
            <div className="form-container">
                <div className={`form-content show`}>
                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder="Nombre personalizado del archivo" />
                    <input type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={fileInputRef} />
                    <button type="button" onClick={() => {
                        if (fileInputRef !== null) {
                            fileInputRef!.current!.click();
                        }
                    }} disabled={!text || isLoading}>Subir archivo</button>
                    {isLoading && <div className="loader" />}
                </div>
            </div>
            {!isLoading && <button className={'modal-close-button'} onClick={onClose}>Cancelar</button>}
        </>
    );
}

export default UploadForm;
