import { useEffect, useMemo, useState } from 'react';
import * as images from './../../assets/images';
import './index.css';

interface JsonObject {
    [key: string]: string | number | null
}

interface TableProps {
    headers: string[];
    keys: string[];
    values?: JsonObject[];
    actionBuilder?: (item: JsonObject) => JSX.Element;
}

export function Table(props: TableProps) {
    const data = useMemo(() => props.values ?? [], [props.values]);


    useEffect(() => {
        setCurrentPage(1);
    }, [data])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, ] = useState(10);
    const totalPages = Math.ceil(data.length / itemsPerPage);

    const currentData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    function getVisiblePages(current: number, total: number) {
        const sidePagesCount = 5;
        let pages: any[] = [];
        if (current <= sidePagesCount) {
            pages = Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1);
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        } else if (current > total - sidePagesCount) {
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1));
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        } else {
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => idx + 1));
            pages.push("...");
            pages.push(current);
            pages.push("...");
            pages.push(...Array.from({ length: sidePagesCount }).map((_, idx) => total - sidePagesCount + idx + 1));
        }

        return pages;
    }


    return (
        <div>
            <table className="styled-table">
                <thead>
                    <tr>
                        {props.headers.map(key => <th key={key}>{key}</th>)}
                        {props.actionBuilder && <th key={'acciones'}>Acciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {currentData!.map((value, index) => <tr key={value?.id ?? index.toString()}>
                        {props.keys.map(key => (<td key={`${key}-${index}`}>{value[key]}</td>))}
                        {props.actionBuilder && <td key={`acciones-${index}`}>{props.actionBuilder(value)}</td>}
                    </tr>)}
                </tbody>
            </table>

            {/* <select value={itemsPerPage} onChange={e => setItemsPerPage(Number(e.target.value))}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
            </select>
            <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Anterior</button>
            <span>{currentPage}</span>
            <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>Siguiente</button> */}
            {totalPages > 1 && (
                <div className="paginator">
                    <button onClick={() => setCurrentPage(1)}>
                        <img src={images.firstPage} alt={'Primera página'} />
                    </button>
                    <button disabled={currentPage === 1} onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}>
                        <img src={images.prevPage} alt={'Página anterior'} />
                    </button>

                    {getVisiblePages(currentPage, totalPages).map((page, index) => {
                        if (page === "...") {
                            return <span key={index}>...</span>
                        }
                        return (
                            <button
                                key={index}
                                className={currentPage === page ? "active" : ""}
                                onClick={() => setCurrentPage(page)}
                            >
                                {page}
                            </button>
                        );
                    })}
                    <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}>
                        <img src={images.nextPage} alt={'Página siguiente'} />
                    </button>
                    <button onClick={() => setCurrentPage(totalPages)} >
                        <img src={images.lastPage} alt={'Última página'} />
                    </button>
                </div>
            )}

        </div>
    );
}