import { useState } from "react";

function CreateClientForm({ onCreate, data, label, disabled = false }: any) {
    const [formData, setFormData] = useState<any>(data ?? {
        first_name: '',
        last_name: '',
        dni: '',
        phone: '',
        email: '',
        address: '',
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onCreate(formData);
        setFormData({
            first_name: '',
            last_name: '',
            dni: '',
            phone: '',
            email: '',
            address: '',
        });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <div className="column">
                    <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        placeholder="Nombres"
                        disabled={disabled}
                    />
                    <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        placeholder="Apellidos"
                        disabled={disabled}
                    />
                    <input
                        type="text"
                        name="dni"
                        id="dni"
                        value={formData.dni}
                        onChange={handleChange}
                        placeholder="Cédula"
                        disabled={disabled}
                    />
                </div>
                <div className="column">
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Teléfono"
                        disabled={disabled}
                    />
                    <input
                        type="text"
                        name="address"
                        id="address"
                        value={formData.address}
                        onChange={handleChange}
                        placeholder="Dirección"
                        disabled={disabled}
                    />
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Correo"
                        disabled={disabled}
                    />
                </div>
                <button disabled={disabled} onClick={handleSubmit}>{label ?? 'Crear Cliente'}</button>
            </div>
        </div>
    );
}

export default CreateClientForm;