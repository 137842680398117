import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import { auth } from '../../firebaseConfig';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Error al cerrar sesión: ', error);
        }
    };
    return (
        <div className="sidebar">
            <ul>
                <li><a href="/clientes" className={location.pathname.includes('cliente') ? 'active' : ''}>Clientes</a></li>
                <li><a href="/equipos" className={location.pathname.includes('equipo') ? 'active' : ''}>Equipos</a></li>
                <li><a href="/servicios" className={location.pathname.includes('servicio') ? 'active' : ''}>Servicios</a></li>
                <li><a href="/usuarios" className={location.pathname.includes('usuario') ? 'active' : ''}>Usuarios</a></li>
            </ul>
            <div style={{ padding: 16 }}>
                <button onClick={handleLogout} className="logout-button">Cerrar Sesión</button>
            </div>
        </div>
    );
}

export default Sidebar;