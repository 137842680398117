import { useState } from "react";

function ChangeUserPasswordForm({ onCreate }: any) {
    const [formData, setFormData] = useState<any>({
        password: '',
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onCreate(formData);
        setFormData({ password: '' });
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="form-container">
            <div className={`form-content show`}>
                <input
                    type="password"
                    name="password"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Contraseña"
                />
                <button onClick={handleSubmit}>{'Actualizar contraseña'}</button>
            </div>
        </div>
    );
}

export default ChangeUserPasswordForm;