import { useEffect, useState } from "react";
import { collection, doc, query, addDoc, updateDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import Modal from "../../components/modal";
import { Table } from "../../components/table";
import CreateUserForm from "../../components/new-user-form";
import ChangeUserPasswordForm from "../../components/change-user-password-form";
import { db } from './../../firebaseConfig';
import * as Sugar from 'sugar';
import axiosInstance from "../../axiosConfig";

const UsuariosPage = () => {
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState<any[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState<any>(null);
    const [userToChangePassword, setUserToChangePassword] = useState<any>(null);

    useEffect(() => {
        let unsubscribe: (() => void) | null = null;
        fetchData().then((unsub) => {
            unsubscribe = unsub;
        });
        return () => {
            if (unsubscribe) { unsubscribe(); }
        };
    }, []);

    const fetchData = async () => {
        const q = query(collection(db, "usuarios"));
        return onSnapshot(q, (querySnapshot) => {
            const users: any[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                users.push(
                    {
                        id: doc.id,
                        ...data,
                        name: Sugar.String.capitalize(`${data?.first_name ?? ''} ${data?.last_name ?? ''}`, true, true),
                        status: (data?.is_enabled ?? false) ? 'Activo' : 'Inactivo',
                    }
                );
            });
            setUsuarios(users);
            setLoading(false);
        });
    };

    function onDataFiltered(evt: any) {
        const { value } = evt.target;
        setFilter(value.toLowerCase());
    }

    async function createUser(data: any) {
        let id = null;
        try {
            const value = { ...data, is_enabled: true, created_at: new Date().getTime() };
            const newUser = await addDoc(collection(db, "usuarios"), value);
            id = newUser.id;
            const password = btoa(data.password);
            await axiosInstance.post('/newUser', {
                _id: id,
                email: data.email,
                name: Sugar.String.capitalize(data.first_name, true, true).trim(),
                password,
            });
            setShowModal(false);
        } catch (error) {
            console.log(error);
            if (id !== null) {
                await deleteDoc(doc(db, "usuarios", id));
            }
        }
    }

    async function modifyUserPassword(data: any) {
        setShowChangePasswordModal(false);
        const password = btoa(data.password.trim());
        await axiosInstance.post('/changeUserPassword', {
            id: userToChangePassword.id,
            password,
        });
        setUserToChangePassword(null);
    }

    async function updateStatus(row: any) {
        const documentRef = doc(db, "usuarios", row.id);
        await updateDoc(documentRef, { is_enabled: !row.is_enabled });
    }

    async function removeUser() {
        setDeleteModal(false);
        await deleteDoc(doc(db, "usuarios", userToDelete.id));
        setUserToDelete(null);
    }

    const displayedData = Sugar.String.isBlank(filter) ? usuarios : usuarios.filter((usuario) => {
        return usuario.name.toLowerCase().includes(filter) || usuario.email.toLowerCase().includes(filter) || usuario.username.toLowerCase().includes(filter);
    });

    const size = displayedData.length;
    
    return (
        <div>
            <h1>{loading ? 'Buscando usuarios...' : (size === 0 ? 'Sin resultados' : `${size} ${Sugar.String.pluralize('Usuario', size)} en total`)}</h1>
            <h3>Listado de usuarios administradores registrados en nuestra plataforma.</h3>
            <div className={'form-filter-container'}>
                <div className="search-container">
                    <input
                        type="text"
                        onChange={onDataFiltered}
                        placeholder="Busca por nombre, correo, usuario..."
                    />
                </div>
                <button onClick={() => { setShowModal(true); }}>{'Nuevo Usuario'}</button>
            </div>
            <Table headers={[
                'Nombres',
                'Usuario',
                'Correo',
                'Estado',
            ]}
                keys={[
                    'name',
                    'username',
                    'email',
                    'status',
                ]}
                values={displayedData}
                actionBuilder={(row) => {
                    return (<div className={'table-actions-container'}>
                        <button className="icon-button icon-primary tooltip-text" data-tooltip="Modificar Contraseña" onClick={() => {
                            setUserToChangePassword(row);
                            setShowChangePasswordModal(true);
                        }}>
                            <i className="fas fa-lock"></i>
                        </button>
                        <button className="icon-button icon-green tooltip-text" onClick={() => { updateStatus(row); }} data-tooltip="Inactivar">
                            <i className={`fas ${row.is_enabled ? 'fa-ban' : 'fa-check'}`}></i>
                        </button>
                        <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                            setUserToDelete(row);
                            setDeleteModal(true);
                        }}>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>);
                }}
            />
            <Modal isShown={showModal}>
                <CreateUserForm onCreate={createUser} />
                <button className={'modal-close-button'} onClick={() => { setShowModal(false); }}>Cancelar</button>
            </Modal>
            <Modal isShown={showChangePasswordModal}>
                <ChangeUserPasswordForm onCreate={modifyUserPassword} />
                <button className={'modal-close-button'} onClick={() => { setShowChangePasswordModal(false); }}>Cancelar</button>
            </Modal>
            <Modal isShown={deleteModal}>
                <h2>Confirmacion de eliminación de usuario</h2>
                <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar a {userToDelete?.name}?</h4>
                <button style={{ marginBottom: 0 }} onClick={removeUser}>Confirmar</button>
                <button className={'modal-close-button'} onClick={() => { setDeleteModal(false); }}>Cancelar</button>
            </Modal>
        </div>
    );
};

export default UsuariosPage;