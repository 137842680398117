import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import Cliente from "../clientes";
import * as images from './../../assets/images';
import './index.css';
import ClientPage from "../cliente";
import VehiclePage from "../vehicle";
import EquipmentPage from "../equipos";
import UsuariosPage from "../usuarios";
import ServicesPage from "../servicios";
import { getAuth } from "firebase/auth";

const Layout = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<any>(null);
    useEffect(() => {
        setTimeout(() => {
            setUser(getAuth().currentUser);
        }, 200);
    }, [getAuth]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const avatarName = (user?.displayName ?? '').split(' ').filter((_: string, index: number) => index === 0 || index % 2 !== 0).map((word: string) => word[0]).join('');

    return (
        <div className="container">
            <div className="header">
                <img src={images.logo} alt="Logo" onClick={() => {
                    navigate('/clientes');
                }} style={{ cursor: 'pointer' }} />
                <div className="user-info">
                    {avatarName}
                </div>
            </div>

            <div className="layout-container">
                <Sidebar />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<Cliente />} />
                        <Route path="/clientes" element={<Cliente />} />
                        <Route path="/cliente/:userId" element={<ClientPage />} />
                        <Route path="/cliente/:userId/vehiculo/:vehicleId" element={<VehiclePage />} />
                        <Route path="/servicios" element={<ServicesPage />} />
                        <Route path="/equipos" element={<EquipmentPage />} />
                        <Route path="/usuarios" element={<UsuariosPage />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Layout;