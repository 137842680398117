import { useEffect, useState } from "react";
import { collection, doc, query, addDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import Modal from "../../components/modal";
import { Table } from "../../components/table";
import CreateServiceForm from "../../components/new-service-form";
import { db } from './../../firebaseConfig';
import * as Sugar from 'sugar';

const ServicesPage = () => {
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [servicios, setServicios] = useState<any[]>([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState<any>(null);

    useEffect(() => {
        let unsubscribe: (() => void) | null = null;
        fetchData().then((unsub) => {
            unsubscribe = unsub;
        });
        return () => {
            if (unsubscribe) { unsubscribe(); }
        };
    }, []);

    const fetchData = async () => {
        const q = query(collection(db, "servicios"));
        return onSnapshot(q, (querySnapshot) => {
            const services: any[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                services.push(
                    {
                        id: doc.id,
                        ...data,
                        formatted_created_at: Sugar.Date.format(new Date(data.created_at), '{dd}-{MM}-{yyyy}'),
                    }
                );
            });
            setServicios(services);
            setLoading(false);
        });
    };

    function onDataFiltered(evt: any) {
        const { value } = evt.target;
        setFilter(value.toLowerCase());
    }

    async function createService(data: any) {
        setShowModal(false);
        const value = { ...data, is_enabled: true, created_at: new Date().getTime() };
        await addDoc(collection(db, "servicios"), value);
    }

    async function removeService() {
        setDeleteModal(false);
        await deleteDoc(doc(db, "servicios", serviceToDelete.id));
        setServiceToDelete(null);
    }

    const displayedData = Sugar.String.isBlank(filter) ? servicios : servicios.filter((servicio) => {
        return servicio.name.toLowerCase().includes(filter) || servicio.description.toLowerCase().includes(filter);
    });
    const size = displayedData.length;
    return (
        <div>
            <h1>{loading ? 'Buscando servicios...' : (size === 0 ? 'Sin resultados' : `${size} ${Sugar.String.pluralize('Servicio', size)} en total`)}</h1>
            <h3>Listado de servicios administradores registrados en nuestra plataforma.</h3>
            <div className={'form-filter-container'}>
                <div className="search-container">
                    <input
                        type="text"
                        onChange={onDataFiltered}
                        placeholder="Busca por nombre, descripción..."
                    />
                </div>
                <button onClick={() => { setShowModal(true); }}>{'Nuevo Servicio'}</button>
            </div>
            <Table headers={[
                'Fecha creación',
                'Nombre',
                'Descripcion',
                'Valor',
            ]}
                keys={[
                    'formatted_created_at',
                    'name',
                    'description',
                    'amount',
                ]}
                values={displayedData}
                actionBuilder={(row) => {
                    return (<div className={'table-actions-container'}>
                        <button className="icon-button icon-red tooltip-text" data-tooltip="Eliminar" onClick={() => {
                            setServiceToDelete(row);
                            setDeleteModal(true);
                        }}>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>);
                }}
            />
            <Modal isShown={showModal}>
                <CreateServiceForm onCreate={createService} />
                <button className={'modal-close-button'} onClick={() => { setShowModal(false); }}>Cancelar</button>
            </Modal>
            <Modal isShown={deleteModal}>
                <h2>Confirmacion de eliminación de servicio</h2>
                <h4 style={{ marginBottom: 16, fontWeight: 400 }}>Estas seguro que deseas eliminar a {serviceToDelete?.name}?</h4>
                <button style={{ marginBottom: 0 }} onClick={removeService}>Confirmar</button>
                <button className={'modal-close-button'} onClick={() => { setDeleteModal(false); }}>Cancelar</button>
            </Modal>
        </div>
    );
};

export default ServicesPage;