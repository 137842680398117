import { createBrowserRouter } from "react-router-dom";
import Login from './views/login';
import Layout from "./views/layout";
import ClientPage from "./views/cliente";
import Clientes from "./views/clientes";
import EquipmentPage from "./views/equipos";
import ServicesPage from "./views/servicios";
import UsuariosPage from "./views/usuarios";

import './App.css';
import VehiclePage from "./views/vehicle";
import AuthGuard from "./auth_guard";

const AppRouter = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <AuthGuard><Layout /></AuthGuard>,
    children: [
      {
        path: "/clientes",
        element: <Clientes />,
      },
      {
        path: "/servicios",
        element: <ServicesPage />,
      },
      {
        path: "/equipos",
        element: <EquipmentPage />,
      },
      {
        path: "/usuarios",
        element: <UsuariosPage />,
      },
      {
        path: "/cliente/:userId",
        element: <ClientPage />,
      },
      {
        path: "/cliente/:userId/vehiculo/:vehicleId",
        element: <VehiclePage />,
      },
    ],
  },
]);

export default AppRouter;
